export const colors = {
  colorBlack: '#000000',
  colorWhite: '#fff',
  colorBlue: '#3b82f6',
  mediumBlue: '#0037ff',
  error: '#F54A4A',
  lightGray: '#E7E7E7',
  primary: '#65A664',
  gray: '#EEEEEE',
  lightMediumGray: '#E9EAEF',
  darkGray: '#7F828B',
  mediumDarkGray: '#A5A5A5',
  purple: '#861dff',
  // lightRed: '#FE889F',
  lightRed: '#FF0064',
  antiqueWhite: '#fbe9da',
};

export const fontsWeight = {
  fontBold: 700,
  fontSemiBold: 600,
  fontSemiMedium: 550,
  fontMedium: 500,
  fontNormal: 400,
  fontLight: 300,
  fontThin: 200,
  fontHairline: 100,
};
